import { BrowserModule } from "@angular/platform-browser";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { KeycloakAngularModule, KeycloakService } from "keycloak-angular";

import { AppRoutingModule } from "./app-routing.module";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
    NbThemeModule,
    NbLayoutModule,
    NbMenuModule,
    NbSidebarModule,
    NbToastrModule,
    NbInputModule,
    NbCheckboxModule,
    NbButtonModule,
    NbTimepickerModule,
    NbDatepickerModule,
    NbSpinnerModule,
    NbCardModule, NbSelectModule, NbIconModule,
} from "@nebular/theme";
import { NbDateFnsDateModule } from "@nebular/date-fns";
import { NbEvaIconsModule } from "@nebular/eva-icons";
import { FullCalendarModule } from "@fullcalendar/angular";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { DragDropModule } from "@angular/cdk/drag-drop";

import {
  HTTP_INTERCEPTORS,
  HttpClientModule,
  HttpClient,
} from "@angular/common/http";
import { TokenInterceptorService } from "src/app/shared/core/services/token-interceptor.service";

import { SharedModule } from "./shared/shared.module";
import { LoginComponent } from "./login/login.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { LoaderService } from "./shared/core/services/loader.service";
import { LoaderInterceptor } from "./shared/core/interceptors/loader.interceptor";
import {DataTablesModule} from "angular-datatables";
import { environment } from "src/environments/environment";

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

FullCalendarModule.registerPlugins([dayGridPlugin, interactionPlugin]);

function initializeKeycloak(keycloak: KeycloakService) {
  return () =>
  {
    try {
      return keycloak.init({
        config: {
          url: environment.keycloakUrl,
          realm: environment.keycloakRealm,
          clientId: environment.keycloakClientId
        },
        initOptions: {
          onLoad: 'check-sso',
          pkceMethod: 'S256',
          checkLoginIframe: false,
          silentCheckSsoRedirectUri: window.location.origin + '/assets/silent-check-sso.html'
        },
        loadUserProfileAtStartUp: true
      }).catch((error) => {
        

      });
    } catch (error) {

    }
  }
    
}
@NgModule({
  declarations: [AppComponent, LoginComponent],
    imports: [
        KeycloakAngularModule,
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        NbThemeModule.forRoot({name: "corporate"}),
        NbMenuModule.forRoot(),
        NbSidebarModule.forRoot(),
        NbLayoutModule,
        NbToastrModule.forRoot(),
        NbDatepickerModule.forRoot(),
        NbTimepickerModule.forRoot(),
        NbDateFnsDateModule,
        NbEvaIconsModule,
        HttpClientModule,
        SharedModule,
        NbInputModule,
        NbCheckboxModule,
        NbButtonModule,
        FormsModule,
        ReactiveFormsModule,
        NbSpinnerModule,
        NbCardModule,
        FullCalendarModule,
        DragDropModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        NbSelectModule,
        NbIconModule,
        DataTablesModule,
    ],
  providers: [
    LoaderService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService]
    },
    { provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true,
    },
    ,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
